import './App.css';
import Home from './pages/Home/Home';
import ArticleDetail from './pages/ArticleDetail/ArticleDetail.js'
import Nav from './components/Nav/Nav.js'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import About from './pages/About/About.js';
import Category from './pages/Category/Category.js';
import Footer from './components/Footer/Footer.js';
import ScrollToTop from './components/ScrollToTop/ScrollToTop.js'
import Author from './pages/Author/Author.js'
import Search from './pages/Search/Search.js';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  return (
    <HelmetProvider>
       <div className="App">
        <Router>
          < ScrollToTop />
          <Nav />
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/search/:q" element={<Search />}></Route>
            <Route path="/article/:slug" element={<ArticleDetail />}></Route>
            <Route path="/about/" element={<About/>}></Route>
            <Route path="/category/:slug" element={<Category />}></Route>
            <Route path="/author/:id" element={<Author />}></Route>
          </Routes>
          <Footer />
        </Router>
      </div>
    </HelmetProvider>
  );
}

export default App;
