import { useFetch } from "../../hooks/UseFetch";
import { timeAgo } from "../../components/timeAgo/timeAgo";
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import React, { useEffect, useState } from "react";
import Pagination from "../../components/Pagination";
import { Helmet } from 'react-helmet-async';

export default function ArticleList({ slug, address, title, rightComponent, leftComponent }) {
    const items = [];
    let url = ''
    if (address === 'search'){
        url = `${address}/${slug}`
    }else if(address === 'article_list'){
        url = `${address}/`
    }else{
        url = `${address}/${slug}/`
    }

    const { data } = useFetch(url);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [currentItems, setCurrentItems] = useState([]);
    const [searchAddress, setSearchAddress] = useState('');
    const [metaDescription, setMetaDescription] = useState('')

    useEffect(() => {
        // Extract search term from slug if address is 'search'
        if (address === 'search') {
            setSearchAddress(slug.replace(/^\?q=/, ''));
        } else {
            setSearchAddress('');
        }
    }, [slug, address]);

    useEffect(() => {
        if (data) {
            let itemsToDisplay;
            if (address === 'search') {
                itemsToDisplay = data.slice(
                    (currentPage - 1) * itemsPerPage,
                    currentPage * itemsPerPage
                );
                setMetaDescription(slug.replace(/^\?q=/, ''))
            }else if(address==='article_list'){
                itemsToDisplay = data.slice(
                    (currentPage - 1) * itemsPerPage,
                    currentPage * itemsPerPage
                );
                setMetaDescription('پیشگامان')
            }else {
                itemsToDisplay = data.articles.slice(
                    (currentPage - 1) * itemsPerPage,
                    currentPage * itemsPerPage
                );
            }
            setCurrentItems(itemsToDisplay);
        }
    }, [data, currentPage, itemsPerPage, address]);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const removeHtmlTags = (htmlString) => {
        return htmlString
            .replace(/<[^>]*>?/gm, '')
            .replace(/&nbsp;/g, ' ')
            .replace(/&zwnj;/g, ' '); 
    };

    for (let i = 1; i <= 10; i++) {
        items.push(
                    <React.Fragment key={i}>
                        <div className="box">
                            <p className="title"><span className="skeleton skeleton-text skeleton-article-title"></span></p>
                            <div>
                                <div className="img responsive-image-parent">
                                    <span className="skeleton skeleton-text skeleton-image"></span>
                                </div>
                                <div className="des-wrapper">
                                    <p className="des">
                                        <span className="skeleton skeleton-text"></span>
                                        <span className="skeleton skeleton-text"></span>
                                        <span className="skeleton skeleton-text"></span>
                                        <span className="skeleton skeleton-text"></span>
                                        <span className="skeleton skeleton-text"></span>
                                        <span className="skeleton skeleton-text skeleton-last"></span>
                                    </p>
                                    <span className="skeleton skeleton-btn btn"></span>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </React.Fragment>
        );
    }

    return (
        <section className='category-last'>
            <Helmet>
                <title>{metaDescription}</title>
                <meta name="description" content={metaDescription} />
            </Helmet>
            {rightComponent && rightComponent}
            <section className="last">
                {address === 'user' && (
                    <h1 className="title">
                        {title} {data && `${data.first_name} ${data.last_name}`}
                    </h1>
                )}
                {address === 'category_detail' && (
                    <h1 className="title">
                        {title} {data && data.title}
                    </h1>
                )}
                {address === 'search' && (
                    <h1 className="title">
                        {title} {searchAddress}
                    </h1>
                )}
                {address === 'article_list' && (
                    <h1 className="title">
                        {title}
                    </h1>
                )}
                <div className="box-wrapper">
                    {!data && items}
                    {currentItems.map((article, index) => (
                        <React.Fragment key={index}>
                            <div className="box">
                                <p className="title"><Link to={`/article/${article.slug}`}>{article.title}</Link></p>
                                <div>
                                    <div className="img responsive-image-parent">
                                        <LazyLoad height={200} offset={100}>
                                            <img src={article.images} alt="Image" loading="lazy" />
                                        </LazyLoad>
                                    </div>
                                    <div className="des-wrapper">
                                        <p className="des">
                                            {removeHtmlTags(article.description.substring(0, 300))}......
                                        </p>
                                        <div className="publish">
                                            توسط: <Link to={`/author/${article.author.id}`} className="author">{article.author.first_name}</Link>در {timeAgo(article.publish)}
                                        </div>
                                        <Link className="btn" to={`/article/${article.slug}`}>ادامه مطلب</Link>
                                    </div>
                                </div>
                            </div>
                            <hr />
                        </React.Fragment>
                    ))}
                    {address === 'search' && data && data.length === 0 && (
                        <p>متاسفانه، نتیجه‌ای برای جستجوی "<strong>{searchAddress}</strong>" پیدا نشد.</p>
                    )}
                </div>
                {data && (
                    <Pagination
                        itemsPerPage={itemsPerPage}
                        totalItems={address === 'search' || address === 'article_list'  ? data.length : data.articles.length}
                        paginate={paginate}
                        currentPage={currentPage}
                    />
                )}
            </section>
            {leftComponent && leftComponent}
        </section>
    );
}
