import { useFetch } from "../../hooks/UseFetch";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import GoogleLoginButton from "../googlelogin";
import useCheckLoginStatus from "../../hooks/CheckLoginStatus";

export default function Nav() {
    const { data: category_list, isLoading } = useFetch('category_list/');
    const [activeSearch, setActiveSearch] = useState(false);
    const [query, setQuery] = useState('');
    const { user: username, status, userFullName, checkLoginStatus } = useCheckLoginStatus();
    const [refreshpage, setRefreshpage] = useState(1);
    const navigate = useNavigate();
    const [active, setActive] = useState(false);
    const [activeIndex, setActiveIndex] = useState(null); // Store the active index

    const toggleActive = () => {
        setActive(prev => !prev);
    };

    const deactive = () => {
        setActive(false);
        setActiveIndex(null);
    }

    const toggleUlActive = (index) => {
        setActiveIndex(prevIndex => (prevIndex === index ? null : index)); // Toggle active state based on index
    };

    const refresh = () => {
        setRefreshpage(refreshpage + 1);
        checkLoginStatus();
    };

    const handleSearch = (e) => {
        e.preventDefault();
        navigate(`search/${query}`);
        handelSearch();
        deactive()
    };

    const handelSearch = () => {
        setQuery('');
        setActiveSearch(false);
        deactive()
    };
 
    return (
        <header>
            <nav className={`${active ? 'show' : ''}`}>
                <div className="btn" onClick={toggleActive}>مینو</div>
                <Link to="/">
                    <div className="logo">
                        <div className="img"></div>
                    </div>
                </Link>
                <ul className="nav">
                    {!category_list && <>
                        <li>
                            <span className="skeleton skeleton-text nav-item" ></span>
                        </li>
                        <li>
                            <span className="skeleton skeleton-text nav-item" ></span>
                        </li>
                        <li>
                            <span className="skeleton skeleton-text nav-item" ></span>
                        </li>
                        <li>
                            <span className="skeleton skeleton-text nav-item" ></span>
                        </li>
                        <li>
                            <span className="skeleton skeleton-text nav-item" ></span>
                        </li>
                        <li>
                            <span className="skeleton skeleton-text nav-item" ></span>
                        </li>
                        <li>
                            <span className="skeleton skeleton-text nav-item" ></span>
                        </li>
                    </>}
                    {category_list && category_list.filter(category => category.parent == null).map((category, index) => (
                        <li key={index} className={`${activeIndex === index ? 'active' : ''}`}>
                            <div className="item">
                                <Link to={`category/${category.slug}`} onClick={deactive}>
                                    {category.title}
                                </Link>
                                <span className="btn" onClick={() => toggleUlActive(index)}></span>
                            </div>
                            {category_list.filter(cat => (cat.parent != null && cat.parent == category.id)).length !== 0 && (
                                <ul>
                                    {category_list.filter(cat => (cat.parent != null && cat.parent == category.id)).map((cat, index) => (
                                        <li key={index}>
                                            <Link to={`category/${cat.slug}`} onClick={deactive}>{cat.title}</Link>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>

                <div className={`liyer ${activeSearch ? 'active' : ''}`} onClick={() => setActiveSearch(false)}></div>
                <div className={`left ${activeSearch ? 'active' : ''}`}>
                    <div className="search-btn" onClick={() => {
                        setActiveSearch(prev => prev ? false : true);
                    }}>
                        <FontAwesomeIcon icon={faSearch} />
                    </div>
                    <form onSubmit={handleSearch}>
                        <input
                            type="search"
                            onChange={e => setQuery(e.target.value)}
                            value={query || ''}
                        />
                        <Link className="btn" to={`search/${query}`} onClick={handelSearch}>جستجو</Link>
                    </form>
                    <div className="login-wrapper">
                        <div className="leyar"></div>
                        {status !== true ? (
                            <div className="header-google-login">
                                <GoogleLoginButton refresh={refresh} />
                            </div>
                        ) : (
                            <div className="header-google-login">
                                <p className="username">{userFullName || username}</p>
                            </div>
                        )}
                    </div>
                </div>
            </nav>
        </header>
    );
}
