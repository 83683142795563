import { useState, useEffect } from "react";
import axios from 'axios';

export default function useCheckLoginStatus() {
    const [status, setStatus] = useState(false);
    const [user, setUser] = useState(null);
    const [userFullName, setUserFullName] = useState(null);
    const [email, setEmail] = useState(null);
    const url = 'https://peshgaman.website/'

    const checkLoginStatus = () => {
        const token = sessionStorage.getItem('peshgaman_token');
        if (token) {
            axios.get(`${url}api/is_login/`, {
                headers: {
                    'Authorization': `Token ${token}`
                }
            })
            .then(response => {
                setStatus(true);
                setUser(response.data.user.replace(/@gmail\.com$/, ''));
                setEmail(response.data.email);
                setUserFullName(response.data.full_name)
            })
            .catch(error => {
                setStatus(false);
                setUser(null);
                setEmail(null);
                setUserFullName(null)
            });
        } else {
            setStatus(false);
            setUser(null);
            setEmail(null);
        }
    };

    useEffect(() => {
        checkLoginStatus();
    }, []);

    return { user, email, status, userFullName, checkLoginStatus };
}
