import ArticleDetailDescription from '../../components/ArticleDetailDescription/ArticleDetailDescription'


export default function ArticleDetail() {

    return (
        <>
            <ArticleDetailDescription />
        </>
    )
}
