import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import useCheckLoginStatus from '../hooks/CheckLoginStatus';

export default function GoogleLoginButton({refresh}) {
  const { checkLoginStatus } = useCheckLoginStatus();

  const handleLoginSuccess = async (response) => {
    const id_token = response.credential;

    if (!id_token) {
      console.error('ID token is missing in the response');
      alert('Login failed: Missing ID token');
      return;
    }

    try {
      const result = await axios.post('https://peshgaman.website/api/auth/token/', {
        token: id_token,
      });

      sessionStorage.setItem('peshgaman_token', result.data.token);
      checkLoginStatus(); // Ensure checkLoginStatus is working as intended
      refresh()
    } catch (error) {
      console.error('Login failed:', error);
      alert('Login failed');
    }
  };

  const handleLoginError = (error) => {
    console.error('Login failed:', error);
    alert('Login failed');
  };

  return (
    <GoogleOAuthProvider clientId="75700861458-un6rt7vvdm8b80032mts8kiaa6ddv2hi.apps.googleusercontent.com">
      <GoogleLogin
        onSuccess={handleLoginSuccess} // Ensure this is the correct prop
        onError={handleLoginError} // Ensure this is the correct prop
      />
    </GoogleOAuthProvider>
  );
}
