import HeaderPost from "../../components/HeaderPost/HeaderPost";
import HotFamousArticle from "../../components/HotFamousArticle/HotFamousArticle";
import MainBody from "../../components/MainBody/MainBody";
import TouchSlider from "../../components/TouchSlider/TouchSlider";

export default function Home(){
    return (
        <>
            <HeaderPost />
            <MainBody />
            <TouchSlider />
            <HotFamousArticle />
        </>
    )
}