import React, { useState, useEffect } from 'react';
import { useFetch } from '../../hooks/UseFetch';
import { useParams } from 'react-router';
import { timeAgo } from '../timeAgo/timeAgo';
import LazyLoad from "react-lazyload";
import parse from 'html-react-parser'; 
import FromThisCategory from './FromThisCategory';
import ArticleDetailSuggest from './ArticleDetailSuggest';
import { Helmet } from 'react-helmet-async';

export default function ArticleDetailDescription() {
    const { slug } = useParams();
    const { data } = useFetch(`article/${slug}/`);
    const [articleDescription, setArticleDescription] = useState('');


    useEffect(() => {
        if (data) {
            setArticleDescription(data.description)   
        }
    }, [data]);

    return (
        <React.Fragment>
            <Helmet>
                {data && <title>{data.title}</title>}
                {data && <meta name="description" content={data.title} />}
            </Helmet>
            <div className='content'>
                <section className="main">
                {!data && <>
                    <span className='skeleton-text skeleton'></span>
                    <span className='skeleton-text skeleton skeleton-last'></span>
                    <span className='skeleton-text skeleton write-by'></span>
                    <span className="img skeleton-image skeleton-article-description skeleton" ></span>
                    <span className='skeleton-text skeleton'></span>
                    <span className='skeleton-text skeleton'></span>
                    <span className='skeleton-text skeleton'></span>
                    <span className='skeleton-text skeleton'></span>
                    <span className='skeleton-text skeleton'></span>
                    <span className='skeleton-text skeleton'></span>
                    <span className='skeleton-text skeleton'></span>
                    <span className='skeleton-text skeleton'></span>
                    <span className='skeleton-text skeleton'></span>
                    <span className='skeleton-text skeleton'></span>
                    <span className='skeleton-text skeleton'></span>
                    <span className='skeleton-text skeleton'></span>
                    <span className='skeleton-text skeleton'></span>
                    <span className='skeleton-text skeleton'></span>
                    <span className='skeleton-text skeleton'></span>
                    <span className='skeleton-text skeleton'></span>
                    <span className='skeleton-text skeleton'></span>
                    <span className='skeleton-text skeleton'></span>
                    <span className='skeleton-text skeleton'></span>
                    <span className='skeleton-text skeleton'></span>
                    <span className='skeleton-text skeleton'></span>
                    <span className='skeleton-text skeleton'></span>
                    <span className='skeleton-text skeleton'></span>
                    <span className='skeleton-text skeleton'></span>
                    <span className='skeleton-text skeleton'></span>
                    <span className='skeleton-text skeleton'></span>
                    <span className='skeleton-text skeleton'></span>
                    <span className='skeleton-text skeleton skeleton-last'></span>
                </>}
                
                    {data && (
                        <>
                            <h1 className="title">{data.title}</h1>
                            <div className="des">
                                نویسنده : <a href={`/author/${data.author.id}/`}>{data.author.first_name}</a> | {timeAgo(data.publish)}
                                <div className="hits">
                                    دسته بندی: {data.category.map(cat => cat.title).join(', ')}
                                </div>
                            </div>
                            <LazyLoad height={200} offset={100}>
                                <img className="img" src={data.images} alt="Article" />
                            </LazyLoad>
                            <div className="main-content">
                                <div>{parse(articleDescription)}</div>
                                <ul className="suggest_video">
                                    <br />
                                    <br />
                                    <p>
                                        ودیو های برگزیده پیشگامان:
                                    </p>
                                    {data.suggestd_video.map((video, index) => (
                                        <li key={index}>
                                            <a href={video.link} target="_blank">{video.title}</a><br/>
                                        </li>
                                    ))}
                                </ul> 
                            </div>
                        </>
                    )}
                </section>
                {data && data.category[0] && <FromThisCategory category={data.category[0].slug} MainArticle={data} />}
            </div>
            {data && data.category[0] && <ArticleDetailSuggest category_slug={data.category[0].slug}  article_id={data.id}/>}
        </React.Fragment>
    );
}
