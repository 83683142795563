import './About.css'
import {useFetch} from '../../hooks/UseFetch'
import parse from 'html-react-parser'; 

export default function About(){
    const {data, isLoading} = useFetch('about/')

    return (
        <section className="about">
            <h1 className="title">در باره ما: </h1>
            <div className="description">
                {data && parse(data[0].description)}
            </div>
        </section>
    )
}