import React from 'react';
import { useFetch } from "../../hooks/UseFetch";
import { timeAgo } from '../timeAgo/timeAgo';
import { Link } from 'react-router-dom';
import LazyBackground from '../LazyBackground'; 

export default function HeaderPost() {
    const { data } = useFetch('header_article/');
 
    return (
        <section className="bests">
            <LazyBackground
                src={data && data.articles.images}
                placeholder="bg"
            >
            <br />
            <Link to={`/article/${data &&  data.articles.slug}/`}>
                <span className="title">{data &&  data.articles.title}</span>
               {!data && <span className='skeleton skeleton-text header-title'></span>}
            </Link>
            <p>{!data &&<span className='skeleton skeleton-text write-by'></span>}{data && <>نوشته شده توسط : </>}<Link to={`author/${data &&  data.articles.author_id}`}>{data &&  data.articles.author}</Link></p>
            <p>{!data &&<span className='skeleton skeleton-text publish-time'></span>}{data && <>زمان انتشار: </>}{data && timeAgo(data.articles.publish)}</p>
            </LazyBackground>
        </section>
    );
}
