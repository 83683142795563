import React from 'react';

const LazyBackground = ({ src, children, placeholder, styleClass }) => {
    const [imageSrc, setImageSrc] = React.useState(placeholder);

    React.useEffect(() => {
        const img = new Image();
        img.src = src;
        img.onload = () => setImageSrc(src);
    }, [src]);

    return (
        <div className={`des ${styleClass ? styleClass : ''}`} style={{ backgroundImage: `url(${imageSrc})` }}>
            {children}
        </div>
    );
};

export default LazyBackground;
