import React from "react"
import { useFetch } from "../../hooks/UseFetch"
import LazyBackground from '../LazyBackground'

export default function FromYoutube(){
    const {data} = useFetch('embed_article/')
    const items = []

    for (let i = 1; i <= 10; i++) {
        items.push(
                    <React.Fragment key={i}>
                        <div className="box">
                            <p className="title"><span className="skeleton skeleton-text skeleton-article-title"></span></p>
                            <div>
                                <div className="des-wrapper">
                                    <p className="des">
                                        <span className="skeleton skeleton-text skeleton-last"></span>
                                    </p>
                                </div>
                                <div className="img responsive-image-parent">
                                    <span className="skeleton skeleton-text skeleton-image"></span>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </React.Fragment>
        );
    }

    return (
        <section className="category category-left">
            <div className="cat">
                <p className="title">از یوتیوب ما :</p>
                    {!data && items}
                    {data && data.map((video, index) => (
                        <React.Fragment key={index}>
                            <hr />
                            <div className="box">
                                <div>	
                                    <p>{video.video_title}</p>  
                                    <div className="title"><a target="_blank" href={video.video_url} >
                                        <LazyBackground
                                            src={video.video_image}
                                            placeholder={video.video_title} 
                                        ></LazyBackground></a>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    ))}
            </div>
        </section>
    )
}