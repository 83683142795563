import Category from "./Category";
import FromYoutube from "./FromYoutube";
import { useFetch } from '../../hooks/UseFetch';
import React, { useEffect, useState } from "react";
import ArticleList from "../ArticleList/ArticleList";

export default function MainBody() {
    const {data : categoryList, isLoading: categoryIsLoading} = useFetch('category_for_main_page/')

    return ( 
        <>
            <ArticleList address="article_list" title="جدید ترین اخبار:" rightComponent={<Category data={categoryList} isLoading={categoryIsLoading} />} 
                leftComponent = {<FromYoutube />}
            />
        </>
    );
}
