import React, { useEffect, useState } from 'react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import { useFetch } from '../../hooks/UseFetch';
import LazyLoad from 'react-lazyload';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default function TouchSlider() {
    const { data } = useFetch('suggest_article/');
    const [slidesPerView, setSlidesPerView] = useState(4);
    const items = []

    useEffect(() => {
        const handleResize = () => {

            // Adjust the slidesPerView based on the window width
            if (window.innerWidth >= 1200) {
                setSlidesPerView(4);
            } else if (window.innerWidth >= 900) {
                setSlidesPerView(3);
            } else if (window.innerWidth >= 600) {
                setSlidesPerView(2);
            } else {
                setSlidesPerView(1);
            }
        };

        window.addEventListener('resize', handleResize);

        // Initial check to set the slidesPerView based on the initial window width
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    for (let i = 1; i <= 4; i++) {
        items.push(
            <SwiperSlide key={i}>
                {/* <Link to='' className="suggest-title"> */}
                    <div className="card skeleton-wrapper">
                        <span className='skeleton skeleton-text touche-slider-skeleton-image'></span>
                        <span className='skeleton skeleton-text'></span>
                        <span className='skeleton skeleton-text skeleton-last'></span>
                    </div>
                {/* </Link> */}
            </SwiperSlide>
        );
    }

    return (
        <section className="suggest">
            <div className="header">
                <div className="title">پیشنهاد ما:</div>
            </div>
            <div className='skeleton-wrapper-suggest'>
                {!data && items}
            </div>
            <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                spaceBetween={50}
                slidesPerView={slidesPerView}
                autoplay={{ delay: 5000 }}
                > 
                {data && data.map((article, index) => (
                    <SwiperSlide key={index}>
                        <Link to={`/article/${article.article.slug}/`} className="suggest-title">
                            <div className="card">
                                <LazyLoad height={200} offset={100}>
                                    <img src={article.article.images} alt="photo" loading="lazy" />
                                </LazyLoad>
                                <p>{article.article.title}</p>
                            </div>
                        </Link>
                    </SwiperSlide>
                ))}
            </Swiper>
        </section>
    );
}
