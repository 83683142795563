import { Link } from "react-router-dom"
import { useFetch } from "../../hooks/UseFetch"
import { timeAgo } from "../timeAgo/timeAgo"
import React from "react"

export default function HotFamousArticle(){
    const {data} = useFetch('host_famous_articles/')
    const items = []

    for (let i = 1; i <= 7; i++) {
        items.push(
                <React.Fragment key={i}>
                    <div className="box from-this-category-skeleton-wrapper">
                        <span className="skeleton skeleton-text"></span>
                        <span className="skeleton skeleton-text"></span>
                        <span className="skeleton skeleton-text skeleton-last"></span>
                    </div>
                </React.Fragment>
        )}

    return (
        <>
            <section className="new-more">
                <h1 className="title">پربازدید ترین اخبار روز</h1>
                <div className="box-wrapper">
                    <div className="box box-larg">
                        <img src={data && data.larg_famous_article.images} alt={data && data.larg_famous_article.title} loading="lazy" />
                        <div className="cover"></div>
                        <div>
                            <div className="title"><Link to={data && `/article/${data.larg_famous_article.slug}`}>{data && data.larg_famous_article.title}</Link></div>
                            {data &&<div className="publish">نوشته شده توسط : <Link to={ `/author/${data.larg_famous_article.author.id}`}>{data.larg_famous_article.author.first_name}</Link><div className="time">در {timeAgo(data.larg_famous_article.publish)}</div></div>}				
                        </div>
                    </div>
                    <div className="box-small">
                        {!data && items}
                        {data && data.famous_article.filter((art) => art.id != data.larg_famous_article.id).map((article, index) => (
                            <div className="box" key={index}>
                                <div className="des">
                                    <div className="title"><Link to={`/article/${article.slug}`}>{article.title}</Link></div>
                                    <div className="publish">نوشته شده توسط <Link to={`/author/${article.author.id}`}>{article.author.first_name}</Link> | {timeAgo(article.publish)}</div>
                                </div>
                            </div> 
                        ))} 
                    </div>			
                </div>
            </section>
            <section className="new-more">
                <h1 className="title">پر بحث ترین اخبار روز</h1>
                <div className="box-wrapper">
                    <div className="box box-larg">
                        <img src={data && data.hot_larg_article.images} alt={data && data.hot_larg_article.title} loading="lazy" />
                        <div className="cover"></div>
                        <div>
                            <div className="title"><Link to={data && `/article/${data.hot_larg_article.slug}`}>{data && data.hot_larg_article.title}</Link></div>
                            {data && <div className="publish">نوشته شده توسط : <Link to={`/author/${data.hot_larg_article.author.id}`}>{data && data.hot_larg_article.author.first_name}</Link><div className="time">در {timeAgo(data.hot_larg_article.publish)}</div></div>}			
                        </div>
                    </div>
                    <div className="box-small">
                        {!data && items}
                        {data && data.hot_Article.filter((art) => art.id != data.hot_larg_article.id).map((article, index) => (
                            <div className="box" key={index}>
                                <div className="des">
                                    <div className="title"><Link to={`/article/${article.slug}`}>{article.title}</Link></div>
                                    <div className="publish">نوشته شده توسط <Link to={`/author/${article.author.id}`}>{article.author.first_name}</Link> | {timeAgo(article.publish)}</div>
                                </div>
                            </div> 
                        ))} 
                    </div>			
                </div>
            </section>
        </>
    )
}