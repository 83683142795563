const Pagination = ({ itemsPerPage, totalItems, paginate, currentPage }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    const handleClick = (number) => {
        paginate(number);
    };

    return (
        <div className="pagination">
            <ul>
                {currentPage > 1 ? (<li className="btn prev" onClick={() => handleClick(currentPage > 1 ? currentPage - 1 : 1)}>
                    <span>
                     قبلی 
                    </span>
                </li>) : ''}
                {currentPage-1 >= 1 ?(<li className='numb' onClick={() => handleClick(currentPage-1)}>
                    <span>{currentPage-1}</span>
                </li>): ''}
                <li className="numb active">
                    <span>
                        {currentPage}
                    </span>
                </li>
                {currentPage+1 <= pageNumbers.length ?(<li className='numb' onClick={() => handleClick(currentPage+1)}>
                    <span>{currentPage+1}</span>
                </li>): ''}
                {currentPage < pageNumbers.length ? (<li className="btn next" onClick={() => handleClick(currentPage < pageNumbers.length ? currentPage + 1 : pageNumbers.length)}>
                    <span>
                     بعدی 
                    </span>
                </li>) : ''}
            </ul>
        </div>
    );
};

export default Pagination;
