import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faYoutube, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { useFetch } from '../../hooks/UseFetch';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
    const { data } = useFetch('category_list/');
    const [firstHalf, setFirstHalf] = useState([]);
    const [secondHalf, setSecondHalf] = useState([]);

    useEffect(() => {
        if (data) {
            const midIndex = Math.floor(data.length / 2);
            setFirstHalf(data.slice(0, midIndex));
            setSecondHalf(data.slice(midIndex));
        }
    }, [data]);

    return (
        <section className="footer">
            <div className="footer-row">
                <div className="footer-col">
                    <h4>اطلاعات</h4>
                    <ul className="links">
                        <li><Link to='/about/'>درباره ما</Link></li>
                        <li><a target='__blank' href="whatsapp://send?phone=+12368330947">تماس با ما</a></li>
                        <li><a target='__blank' href="https://nasirahmadahmady.com">طراح و برنامه نویس</a></li>
                    </ul>
                </div>

                <div className="footer-col">
                    <ul className="links">
                        {secondHalf.map((cat, index) => (
                            <li key={index}>
                                <Link to={`category/${cat.slug}`}>{cat.title}</Link>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="footer-col">
                    <ul className="links">
                        {firstHalf.map((cat, index) => (
                            <li key={index}>
                                <Link to={`category/${cat.slug}`}>{cat.title}</Link>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="footer-col">
                    <h4>پیشگامان را در شبکه‌های اجتماعی دنبال کنید</h4>
                    <p>شبکه‌های اجتماعی پیشگامان سریع‌ترین روش برای دسترسی به اخبار فناوری، علم و خودرو هستند. اگر می‌خواهید همواره به‌روز باشید، شبکه‌های اجتماعی پیشگامان را دنبال کنید.</p>
                    <div className="icons">
                        <a target='_blank' href='https://www.facebook.com/profile.php?id=61555482398369&mibextid=ZbWKwL'><FontAwesomeIcon icon={faFacebookF} /></a>
                        <a target='_blank' href='https://nasirahmadahmady.com'><FontAwesomeIcon icon={faGlobe} /></a>
                        <a target='_blank' href='https://www.youtube.com/@Peshgaman'><FontAwesomeIcon icon={faYoutube} /></a>
                        <a target='_blank' href='https://github.com/nasir-ahmad3'><FontAwesomeIcon icon={faGithub} /></a>
                    </div>
                </div>
            </div>
        </section>
    );
}
