import { Link } from "react-router-dom"
import { useFetch } from "../../hooks/UseFetch"
import { timeAgo } from "../timeAgo/timeAgo"
import React from "react"

export default function FromThisCategory({category, MainArticle}){
    const{data} = useFetch(`category_detail/${category}/`) 
    const items = []

    for (let i = 1; i <= 10; i++) {
        items.push(
                <React.Fragment key={i}>
                    <div className="box from-this-category-skeleton-wrapper">
                        <span className="skeleton skeleton-text"></span>
                        <span className="skeleton skeleton-text"></span>
                        <span className="skeleton skeleton-text skeleton-last"></span>
                    </div>
                </React.Fragment>
        )}

    return (
        <section className="new-more">
            <h1 className="title">از این دسته بندی</h1>
            
            <div className="box-wrapper">
                <div className="box-small">  
                    {!data && items}                  
                    {data && data.articles.filter((art) => art.id != MainArticle.id).map((article, index) => (
                        <div className="box" key={index}>
                            <div className="des">
                                <div className="title"><Link to={`/article/${article.slug}`}>{article.title}</Link></div>
                                <div className="publish">نوشته شده توسط:  <Link to={`/author/${article.author.id}`}>{article.author.first_name}</Link> | {timeAgo(article.publish)}</div>
                            </div>
                        </div>
                    ))}
                    <div className="box">
                        {data && data.articles.filter((art) => art.id != MainArticle.id).length == 0 ? <div>متاسفانه محتویاتی برای نمایش وجود ندارد </div> : ''}
                    </div>
                </div>			
            </div>
        </section>
    )
}