import React from "react"
import { Link } from "react-router-dom"

export default function Category({data}){
    const items = [];

    for (let i = 1; i <= 10; i++) {
        items.push(
                    <React.Fragment key={i}>
                        <p className="title">
                    <span className="skeleton skeleton-text skeleton-cat-title"></span>
                </p>
                <div className="box">
                    <div>
                        <div className="title">
                            <span className="skeleton skeleton-text"></span>
                            <span className="skeleton skeleton-text"></span>
                            <span className="skeleton skeleton-text skeleton-last"></span>
                        </div>
                    </div>
                </div>
                <div className="box">
                    <div>
                        <div className="title">
                            <span className="skeleton skeleton-text"></span>
                            <span className="skeleton skeleton-text skeleton-last"></span>
                        </div>
                    </div>
                </div>
                <div className="box">
                    <div>
                        <div className="title">
                            <span className="skeleton skeleton-text"></span>
                            <span className="skeleton skeleton-text skeleton-last"></span>
                        </div>
                    </div>
                </div>
                    </React.Fragment>
        );
    }
    return ( 
        <section className="category category-right">
            <div className="cat">
                {!data && items}
                {data && data.filter((cat) => cat.articles.length != 0).map((cat, index) => (
                    <React.Fragment key={index}>
                        <p className="title">{cat.title}</p>				
                        <hr />
                        {cat.articles.map((article, index) => {
                            // Check the condition and return null if the condition is met
                            if (index >= 3) {
                                return null;
                            }

                            return (
                                <div className="box" key={index}>
                                    <div>
                                        <div className="title">
                                            <Link to={`/article/${article.slug}`}>{article.title}</Link>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                    </React.Fragment>			
                ))}
            </div>
        </section>
    )
}