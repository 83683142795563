import { useParams } from "react-router"
import ArticleList from '../../components/ArticleList/ArticleList'

export default function Search(){
    const {q} = useParams()
    return(
        <>
            <ArticleList title="جستجوی: "  address="search" slug={`?q=${q}`}/>
        </>
    )
}