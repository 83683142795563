import { useState, useEffect } from 'react';
import { useFetch } from '../../hooks/UseFetch';
import { Link } from 'react-router-dom';

export default function ArticleDetailSuggest({ category_slug, article_id }) {
    const { data } = useFetch(`article_detail_suggest/?category=${category_slug}`);
    const [firstHalf, setFirstHalf] = useState([]);
    const [secondHalf, setSecondHalf] = useState([]);

    useEffect(() => {
        if (data) {
            const filteredArticles = data.filter((art) => art.id !== article_id);
            const midIndex = Math.floor(filteredArticles.length / 2);
            setFirstHalf(filteredArticles.slice(0, midIndex).filter((item, index) => index < 4));
            setSecondHalf(filteredArticles.slice(midIndex).filter((item, index) => index < 4));
        }
    }, [data, article_id]);

    return (
        <section className="article-suggest">
                {firstHalf.length != 0 ?
                    <>
                    <h1 className="title">پیشنهاد پیشگامان</h1>
                    <div className="box-wrapper">
                        {firstHalf.map((article, index) => (
                            <div className="box" key={index}>
                                <p className="des"><Link to={`/article/${article.slug}/`}>{article.title}</Link></p>
                            </div>
                        ))}
                    </div>
                    </> : ''
                }
            <div id="pos-article-text-90148"></div>
            {secondHalf.length !=  0 ? 
                <>
                    <h1 className="title">پیشنهاد سردبیر</h1>
                    <div className="box-wrapper">
                        {secondHalf.map((article, index) => (
                            <div className="box" key={index}>
                                <p className="des"><Link to={`/article/${article.slug}/`}>{article.title}</Link></p>
                            </div>
                        ))}
                    </div>
                </> : ''
            }
        </section>
    );
}
